import {makeAutoObservable} from 'mobx';
import {getTime} from "html-evaluate-utils/DateFormat";
import {lc_user} from "clicker-common/src/staticData/lc_user";

export class AuthState {

    user = lc_user;
    serverToken = null;

    constructor() {
        makeAutoObservable(this);
    }

    setup(rootStore, caller) {
        this.logEntry('setup', caller);
    }

    authHeader(caller) {
        this.logEntry('authHeader', caller);
        if(this.serverToken && typeof this.serverToken === 'string' && this.serverToken.length > 0) {
            return { Authorization: 'Bearer ' + this.serverToken};
        } else {
            return {};
        }
    }

    logEntry(method, caller) {
        console.log(`${getTime()} AuthState.${method}() <- ${caller}`);
    }
}
