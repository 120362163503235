import {initializeApp} from 'firebase/app'
import {getAuth} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDXHDex96EY-8zo2JRf8Q3m2esYH4r3Aj4",
  authDomain: "nosqlstore.firebaseapp.com",
  databaseURL: "https://nosqlstore-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "nosqlstore",
  storageBucket: "nosqlstore.appspot.com",
  messagingSenderId: "773769589820",
  appId: "1:773769589820:web:eb10729254dd793d963f39"
};

export const firebaseApp = initializeApp(firebaseConfig)
export const auth = getAuth(firebaseApp);
