import {useNavigate} from "react-router-dom";
import {auth} from "../../../utils/firebase";
import {
    signOut
} from "firebase/auth";

export default function useLogOut() {
    const navigate = useNavigate();

    return async (event) => {
        try {
            event.preventDefault();
            await signOut(auth);
            navigate("/");
        } catch (error) {
            throw new Error(error);
        }
    };
}