import {lazy, Suspense} from 'react';
import {createBrowserRouter, createRoutesFromElements, Outlet, Route, Routes} from 'react-router-dom';
import RootLayout from 'src/routes/layouts/root/RootLayout';
import ErrorPage from "./ErrorPage";
import PublicLayout from "./layouts/public/PublicLayout";
import Policy from "./public/policy";

export const IndexPage = lazy(() => import('src/routes/public/Home'));
export const AboutPage = lazy(() => import('src/routes/public/About'));
export const ClickerCfgPage = lazy(() => import('src/routes/ClickerCfg'));
export const RoleBuilderPage = lazy(() => import('src/routes/RoleBuilder'));
export const ProfilePage = lazy(() => import('src/routes/Profile'));
export const LinkedInAccPage = lazy(() => import('src/routes/LinkedInAcc'))

// ----------------------------------------------------------------------

export const router = createBrowserRouter(
    createRoutesFromElements(
        [
            <Route
                path="/"
                element={(
                    <RootLayout>
                        <Suspense>
                            <Outlet/>
                        </Suspense>
                    </RootLayout>
                )}
                errorElement={<ErrorPage/>}
            >
                <Route errorElement={<ErrorPage/>}>
                    <Route
                        path="role-builder"
                        element={<RoleBuilderPage/>}
                    />
                    <Route
                        path="cfg-config"
                        element={<ClickerCfgPage/>}
                    />
                    <Route
                        path="profile"
                        element={<ProfilePage/>}
                    />
                    <Route
                        path="linkedIn-acc"
                        element={<LinkedInAccPage/>}
                    />
                </Route>
            </Route>,
            <Route
                path="/"
                element={(
                    <PublicLayout>
                        <Suspense>
                            <Outlet/>
                        </Suspense>
                    </PublicLayout>
                )}
                errorElement={<ErrorPage/>}
            >
                <Route errorElement={<ErrorPage/>}>
                    <Route index element={<IndexPage/>}/>
                    <Route
                        path="about"
                        element={<AboutPage/>}
                    />
                    <Route
                        path="private-policy"
                        element={<Policy/>}
                    />
                </Route>
            </Route>
        ]
    )
);
