import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Avatar from '@mui/material/Avatar';
import {alpha} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';

import {usePathName} from 'src/routes/hooks';
import {RouterLink} from 'src/routes/components';
import {useResponsive} from 'src/hooks/use-responsive';
import {lc_user as account} from 'clicker-common/src/staticData/lc_user';
import Logo from 'src/components/logo';
import Scrollbar from 'src/components/scrollbar';

import {NAV} from './LayoutConfig';
import navConfig from './NavConfig';
import {Chip} from "@mui/material";
import useLogOut from "../../auth/Login/useLogOut";

// ----------------------------------------------------------------------

export default function Nav({user, openNav, onCloseNav}) {
    const pathname = usePathName();

    const upLg = useResponsive('up', 'lg');

    useEffect(() => {
        if (openNav) {
            onCloseNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const handleOnLogOut = useLogOut();

    const renderAccount = (
        <Box
            sx={{
                my: 3,
                mx: 2.5,
                py: 2,
                px: 2.5,
                display: 'flex',
                borderRadius: 1.5,
                alignItems: 'center',
                bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
            }}
        >
            <Avatar src={account.photoURL} alt="photoURL"/>

            <Box sx={{ml: 2}}>
                <Typography variant="subtitle2">{account.displayName}</Typography>
            </Box>
        </Box>
    );

    const renderMenu = (
        <Stack component="nav" spacing={0.5} sx={{px: 2}}>
            {navConfig.map((item) => (
                <NavItem key={item.title} item={item}/>
            ))}
        </Stack>
    );

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': {
                    height: 1,
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >
            <Logo sx={{mt: 3, ml: 4}}/>

            {renderMenu}

            <Box sx={{flexGrow: 1}}/>

            {user && (
                <Chip sx={{
                    width:  "60%",
                    marginBottom: "15px",
                    marginLeft: "20%"
                }} label="LogOut" onClick={handleOnLogOut}/>
            )}

        </Scrollbar>
    );

    return (
        <Box
            sx={{
                flexShrink: {lg: 0},
                width: {lg: NAV.WIDTH},
            }}
        >
            {upLg ? (
                <Box
                    sx={{
                        height: 1,
                        position: 'fixed',
                        width: NAV.WIDTH,
                        borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
                    }}
                >
                    {renderContent}
                </Box>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    PaperProps={{
                        sx: {
                            width: NAV.WIDTH,
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </Box>
    );
}

Nav.propTypes = {
    openNav: PropTypes.bool,
    onCloseNav: PropTypes.func,
};

// ----------------------------------------------------------------------

function NavItem({item}) {
    const pathname = usePathName();

    const active = item.path === pathname;

    return (
        <ListItemButton
            component={RouterLink}
            href={item.path}
            sx={{
                minHeight: 44,
                borderRadius: 0.75,
                typography: 'body2',
                color: 'text.secondary',
                textTransform: 'capitalize',
                fontWeight: 'fontWeightMedium',
                ...(active && {
                    color: 'primary.main',
                    fontWeight: 'fontWeightSemiBold',
                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                    '&:hover': {
                        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
                    },
                }),
            }}
        >
            <Box component="span" sx={{width: 24, height: 24, mr: 2}}>
                {item.icon}
            </Box>

            <Box component="span">{item.title} </Box>
        </ListItemButton>
    );
}

NavItem.propTypes = {
    item: PropTypes.object,
};
