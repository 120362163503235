import { useState } from 'react';
import PropTypes from 'prop-types';
import {useAuthState} from "react-firebase-hooks/auth";
import Box from '@mui/material/Box';
import {auth} from "../../../utils/firebase";
import Nav from './Nav';
import Main from './Main';
import Header from './Header';
import {Chip} from "@mui/material";
import useLogin from "../../auth/Login/useLogin";
import {LOGIN_MAIN, NAV} from "./LayoutConfig";

// ----------------------------------------------------------------------

export default function RootLayout({ children }) {

  const [openNav, setOpenNav] = useState(false);

  const [user, loading, error] = useAuthState(auth);

  const handleOnLogin = useLogin("/profile");

  return (
    <>
      <Header user={user} onOpenNav={() => setOpenNav(true)} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        <Nav user={user} openNav={openNav} onCloseNav={() => setOpenNav(false)} />

          {user && (
              <Main>{children}</Main>
          )}
          {!user && (
                  <Main sx={{
                      display: 'flex',
                      width:  "100%",
                      marginLeft: '10%',
                      marginTop: '5%'
                  }}>
                      <Chip sx={{
                          flexShrink: {lg: 0},
                          width: {lg: NAV.WIDTH},

                      }}
                          label="LogIn" onClick={handleOnLogin} />
                  </Main>
          )}
      </Box>
    </>
  );
}

RootLayout.propTypes = {
  children: PropTypes.node,
};
