import {makeAutoObservable} from 'mobx';
import {getTime} from "html-evaluate-utils/DateFormat";
import {lc_profile} from "clicker-common/src/staticData/lc_profile";
import axios from "axios";
import {API_URL, CLICKER_USER_BY_USER_ID} from "clicker-common/src/Config";
import {DEV_MODE} from "./Config";

export class ClickerUserState {

    authState = null;
    clickerUser = null;

    constructor() {
        makeAutoObservable(this);
    }
    
    setup(authState, caller) {
        console.log(getTime() + " ClickerUserState.setup() <-" + caller);
        this.authState = authState;
        this.setClickerUser(lc_profile, " ClickerUserState.setup() <-" + caller);
    }

    setClickerUser(clickerUser, caller) {
        console.log(getTime() + " ClickerUserState.setProfile() <-" + caller);
        this.clickerUser = clickerUser;
    }

    loadFromServiceByUserId(uid, caller) {
        console.log(getTime() + " ClickerUserState.loadFromServiceByUserId() <-" + caller);
        const endPointUrl = DEV_MODE ? API_URL : CLICKER_USER_BY_USER_ID;
        if(uid && uid !== 0) {
                return axios
                    .get(endPointUrl + "clickerUserFindByUserId?uid=" + uid, {headers: this.authState.authHeader(" ClickerUserState.loadFromServiceByUserId() <-" + caller)})
                    .then((response) => {
                        if (response.status === 200) {
                            this.setClickerUser(
                                response.data,
                                " ClickerUserState.loadFromServiceByUserId(), response status 200 <-" + caller);
                            return this.clickerUser;
                        } else {
                            const reason = "ClickerUserState.loadFromServiceByUserId, response status not 200 <-" + caller;
                            console.error(reason);
                            return Promise.reject(reason);
                        }
                    })
                    .catch(reason => {
                        console.error("ClickerUserState.loadFromServiceByUserId reason " + reason);
                        return Promise.reject(reason);
                    });
        } else {
            const reason = "ClickerUserState.loadFromServiceByUserId uid undefined";
            console.error(reason);
            return Promise.reject(reason);
        }
    }

}