import {createRoot} from 'react-dom/client';
import {Provider} from "mobx-react";
import {connectAuthEmulator} from "firebase/auth";

import {DEV_MODE} from "./state/Config";
import {RootState} from "./state/RootState";
import App from "./App";

if(DEV_MODE){
  //  connectAuthEmulator(auth, "http://127.0.0.1:9099");
}

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const rootState = new RootState();

root.render(
    <Provider
        rootState={rootState}
        ruleState={rootState.ruleState}
        authState={rootState.authState}
        actorState={rootState.actorState}
        paymentInfoStore={rootState.paymentInfoStore}
        subscriptionDetailsState={rootState.subscriptionDetailsState}
        cfgState={rootState.cfgState}
        pluginState={rootState.pluginState}
        linkedInAccState={rootState.linkedInAccState}
        clickerUserState={rootState.clickerUserState}>
                    <App />
    </Provider>
);
