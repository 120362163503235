import {useNavigate} from "react-router-dom";
import {auth} from "../../../utils/firebase";
import {
    GoogleAuthProvider,
    signInWithRedirect,
    signInWithPopup,
    getRedirectResult
} from "firebase/auth";
import {LOGIN_WITH_REDIRECT} from "../../../state/Config";

export default function useLogin({returnPage}) {
    const navigate = useNavigate();
    return async (event) => {
        try {
            event.preventDefault();
            const provider = new GoogleAuthProvider();
            provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
            if(LOGIN_WITH_REDIRECT === true) {
                await signInWithRedirect(auth, provider);
                const userCredential = await getRedirectResult(auth);
                if(userCredential !== null){
                    navigate(returnPage);
                }
            } else {
                const userCredential = await signInWithPopup(auth, provider);
                if(userCredential !== null){
                    navigate(returnPage);
                }
            }
        } catch (error) {
            throw new Error(error);
        }
    };
}