import {SubscriptionDetailsState} from "./SubscriptionDetailsState";
import {getTime} from "html-evaluate-utils/DateFormat";
import {PluginState} from "./PluginState";
import {CfgState} from "./CfgState";
import {LinkedInAccState} from "./LinkedInAccState";
import {AuthState} from "./AuthState";
import {ClickerUserState} from "./ClickerUserState";
import {PaymentInfoState} from "./PaymentInfoState";
import {onAuthStateChanged} from 'firebase/auth';
import {auth} from "../utils/firebase";
import {lc_subscription_details} from "clicker-common/src/staticData/lc_subscription_details";
import {lc_cfg} from "clicker-common/src/staticData/lc_cfg";
import {lc_linkedInAcc} from "clicker-common/src/staticData/lc_linkedInAcc";
import {lc_user} from "clicker-common/src/staticData/lc_user";
import {RuleState} from "./RuleState";
import {ActorState} from "./ActorState";

export class RootState {

    prefix = "lc_";
    subscriptionDetailsState = null;
    pluginState = null;
    cfgState = null;
    linkedInAccState = null;
    authState = null;
    clickerUserState = null;
    paymentInfoStore = null;
    ruleState = null;
    actorState = null;

    constructor() {
        this.init(" RootState.constructor()");
    }

    init(caller){
        console.log(getTime() + " RootState.init() <-" + caller);
        this.authState = new AuthState();
        this.subscriptionDetailsState = new SubscriptionDetailsState();
        this.pluginState = new PluginState();
        this.cfgState = new CfgState();
        this.linkedInAccState = new LinkedInAccState();
        this.clickerUserState = new ClickerUserState();
        this.paymentInfoStore = new PaymentInfoState();
        this.ruleState = new RuleState();
        this.actorState = new ActorState();
        this.setupStoreRelationships(" RootState.init() <-" + caller);
        this.setupEventListener(" RootState.init() <-" + caller);
    }

    setupStoreRelationships(caller) {
        console.log(getTime() + " RootState.setupStoreRelationShip() <-" + caller);
        this.authState.setup(this, " RootState.setupStoreRelationShip() <-" + caller);
        this.subscriptionDetailsState.setup(this.authState, " RootState.setupStoreRelationShip() <-" + caller);
        this.pluginState.setup(" RootState.setupStoreRelationShip() <-" + caller);
        this.cfgState.setup(this.authState, " RootState.setupStoreRelationShip() <-" + caller);
        this.linkedInAccState.setup(this.authState, " RootState.setupStoreRelationShip() <-" + caller);
        this.clickerUserState.setup(this.authState, " RootState.setupStoreRelationShip() <-" + caller);
        this.paymentInfoStore.setup(this.authState, this.subscriptionDetailsState, " RootState.setupStoreRelationShip() <-" + caller);
        this.ruleState.setup(this, this.cfgState , " RootState.setupStoreRelationShip() <-" + caller);
        this.actorState.setup(this, this.cfgState, " RootState.setupStoreRelationShip() <-" + caller);
    }

    setupEventListener(caller){
        console.log(getTime() + " RootState.setupEventListener() <-" + caller);
        window.addEventListener('message', (event) => {
            if (event.data.type === 'lc_get_is_clicker_installed_response') {
                this.pluginState.setPluginInstalled(true, " RootState.constructor() window.addEventListener " + caller);
            }
        });
        onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser !== null && currentUser.uid !== null && !currentUser.isAnonymous) {
                console.log(getTime() + " RootState.onAuthChange() user login " + caller);
                this.authState.user = currentUser;
                this.authState.serverToken = await currentUser.getIdToken(true);
            } else {
                console.log(getTime() + " RootState.onAuthChange() user logout " + caller);
                await this.clearDataOnLogOut(" RootState.onAuthChange() <-" + caller);
            }
        });
    }

    async clearDataOnLogOut(caller) {
        console.log(getTime() + " RootState.clearDataOnLogOut() " + caller);
        //this.authState = new AuthState();
        this.subscriptionDetailsState.setSubscriptionDetails(lc_subscription_details);
        // this.pluginState = new PluginState();
        this.cfgState.setRootCfg(lc_cfg);
        this.linkedInAccState.setLinkedInAcc(lc_linkedInAcc);
        this.authState.serverToken = null;
        this.authState.user = lc_user;
       // await sessionStorage.removeItem('idToken');
        //this.clickerUserState = new ClickerUserState();
        //this.paymentInfoStore = new PaymentInfoState();
        // this.setupStoreRelationships(" RootState.init() <-" + caller);
    }

}
