import { useState } from 'react';
import PropTypes from 'prop-types';
import {useAuthState} from "react-firebase-hooks/auth";
import Box from '@mui/material/Box';
import {auth} from "../../../utils/firebase";
import Nav from '../root/Nav';
import Main from '../root/Main';
import Header from './Header';

// ----------------------------------------------------------------------

export default function PublicLayout({ children }) {

  const [openNav, setOpenNav] = useState(false);

  const [user, loading, error] = useAuthState(auth);

  return (
    <>
      <Header user={user} onOpenNav={() => setOpenNav(true)} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        <Nav user={user} openNav={openNav} onCloseNav={() => setOpenNav(false)} />
        <Main>{children}</Main>
      </Box>
    </>
  );
}

PublicLayout.propTypes = {
  children: PropTypes.node,
};
