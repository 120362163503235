import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {useTheme} from '@mui/material/styles';
import IconButton from '@mui/material/IconButton'
import {useResponsive} from 'src/hooks/use-responsive';
import {bgBlur} from 'src/theme/css';
import {auth} from "../../../utils/firebase";
import Iconify from 'src/components/iconify';
import useLogin from "../../auth/Login/useLogin";
import {NAV, HEADER} from '../root/LayoutConfig';
import {Chip, Link} from "@mui/material";
import React from "react";

// ----------------------------------------------------------------------

export default function Header({user, onOpenNav}) {
    const theme = useTheme();

    const handleOnLogin = useLogin("/profile");

    const lgUp = useResponsive('up', 'lg');

    const getUserPhoto = () => {
        let result = "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80";
        if (user && auth.currentUser.photoURL) {
            result = auth.currentUser.photoURL;
        }
        return result;
    }

    const renderContent = (
        <>
            {!lgUp && (
                <IconButton onClick={onOpenNav} sx={{mr: 1}}>
                    <Iconify icon="eva:menu-2-fill"/>
                </IconButton>
            )}

            <Box sx={{flexGrow: 1}}/>

            <Stack direction="row" alignItems="left" spacing={2}>
                {user && (
                    <Link sx={{width: "65%"}} onClick={() => navigate("/profile")}>
                        <img
                            src={getUserPhoto()}
                            alt="Avt."
                        />
                    </Link>
                )}
                {!user && (
                    <Chip sx={{
                        flexShrink: {lg: 0},
                        width: "80px",

                    }}
                          label="LogIn" onClick={handleOnLogin}/>
                )}
            </Stack>
        </>
    );

    return (
        <AppBar
            sx={{
                boxShadow: 'none',
                height: HEADER.H_MOBILE,
                zIndex: theme.zIndex.appBar + 1,
                ...bgBlur({
                    color: theme.palette.background.default,
                }),
                transition: theme.transitions.create(['height'], {
                    duration: theme.transitions.duration.shorter,
                }),
                ...(lgUp && {
                    width: `calc(100% - ${NAV.WIDTH + 1}px)`,
                    height: "80px",
                    paddingTop: "10px"
                }),
            }}
        >
            <Toolbar>
                {renderContent}
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {
    onOpenNav: PropTypes.func,
};
