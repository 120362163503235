import {makeAutoObservable} from 'mobx';
import axios from "axios";
import {
    API_URL, SAVE_PAYMENT_INFO, PAYMENT_INFO_BY_USER_ID
} from "clicker-common/src/Config";
import {lc_subscription_details} from "clicker-common/src/staticData/lc_subscription_details";
import {getTime} from "html-evaluate-utils/DateFormat";
import {DEV_MODE} from "./Config";

export class PaymentInfoState {

    authState = null;
    paymentInfo = null;
    subscriptionDetailsState = null;

    constructor() {
        makeAutoObservable(this);
    }

    setup(authState, subscriptionDetailsState, caller) {
        console.log(getTime() + " PaymentInfoState.setup() <-" + caller);
        this.authState = authState;
        this.subscriptionDetailsState = subscriptionDetailsState;
        this.setPaymentInfo(lc_subscription_details, " PaymentInfoState.setup() <-" + caller);
    }

    setPaymentInfo(paymentInfo, caller) {
        console.log(getTime() + " PaymentInfoState.setPaymentInfo() <-" + caller);
        this.paymentInfo = paymentInfo;
    }

    save(paymentInfo, uid, caller) {
        console.log(getTime() + " PaymentInfoState.save() <-" + caller);
        this.setPaymentInfo(paymentInfo, " PaymentInfoState.save() <-" + caller);
        const endPointUrl = DEV_MODE ? API_URL : SAVE_PAYMENT_INFO;
        if (uid && uid !== 0) {
            paymentInfo.uid = uid;
            axios
                .post(
                    endPointUrl + "savePaymentInfo?uid=" + uid,
                    paymentInfo,
                    {headers: this.authState.authHeader(" PaymentInfoState.save() <-" + caller)})
                .then((response) => {
                    if (response.status === 200) {
                        this.subscriptionDetailsState.setSubscriptionDetails(response.data, " PaymentInfoState.save() <-" + caller);
                    } else {
                        const reason = "PaymentInfoState.save, response status not 200 <-" + caller;
                        console.error(reason);
                    }
                })
                .catch(reason => {
                    console.error("PaymentInfoState.save reason " + reason);
                });
        } else {
            const reason = "PaymentInfoState.save uid undefined";
            console.error(reason);
        }
    };

    loadFromServiceByUserId(uid, caller) {
        console.log(getTime() + " PaymentInfoState.loadFromServiceByUserId() <-" + caller);
        const endPointUrl = DEV_MODE ? API_URL : PAYMENT_INFO_BY_USER_ID;
        if (uid && uid !== 0) {
            axios
                .get(endPointUrl + "paymentInfoByUserId?uid=" + uid,
                    {headers: this.authState.authHeader(" PaymentInfoState.loadFromServiceByUserId() <-" + caller)})
                .then((response) => {
                    if (response.status === 200) {
                        this.setPaymentInfo(
                            response.data,
                            " PaymentInfoState.loadFromServiceByUserId(), response status 200 <-" + caller);
                    } else {
                        const reason = "PaymentInfoState.loadFromServiceByUserId, response status not 200 <-" + caller;
                        console.error(reason);
                    }
                })
                .catch(reason => {
                    console.error("PaymentInfoState.loadFromServiceByUserId reason " + reason);
                });
        } else {
            const reason = "PaymentInfoState.loadFromServiceByUserId uid undefined";
            console.error(reason);
        }
    };

}