import {makeAutoObservable} from 'mobx';
import {getTime} from "html-evaluate-utils/DateFormat";
import {auth} from "../utils/firebase";
export class PluginState {

    linkedInPluginInstalled =  "Need to Install plugin";
    isInstalledTimeOut = null;
    setUserDataInterval = null;
    constructor() {
        makeAutoObservable(this);
    }

    setup(caller) {
        console.log(getTime() + " PluginState.setup() <-" + caller);
        this.isInstalledTimeOut = setTimeout(this.initGetPluginInstalled.bind(this), 1000, " PluginState.setup()");
        this.setUserDataInterval = setInterval(this.setUserData.bind(this), 5000, " PluginState.setup()");
    }

    setPluginInstalled(value, caller){
        console.log(getTime() + " PluginState.setPluginInstalled(), value = " + value + " <-" + caller);
        if (value) {
            this.linkedInPluginInstalled = "Plugin Is Installed";
        } else {
            this.linkedInPluginInstalled = "Need to Install plugin";
        }
        this.resetTimeOut(" PluginState.setPluginInstalled()");
    }

    resetTimeOut(caller){
        console.log(getTime() + " PluginState.resetTimeOut() <-" + caller);
        clearTimeout(this.isInstalledTimeOut);
        this.isInstalledTimeOut = setTimeout(this.initGetPluginInstalled.bind(this), 5000, " PluginState.resetTimeOut()");
    }

    initGetPluginInstalled(caller){
        console.log(getTime() + " PluginState.initGetPluginInstalled() <-" + caller);
        window.postMessage({
            type: 'lc_get_is_clicker_installed'
        }, '*');
    }

    async setUserData(caller) {
        console.log(getTime() + " PluginState.setUserData() <-" + caller);
        if(auth != null && auth.currentUser !== null){
            window.postMessage({
                type: 'lc_set_auth_token',
                result: await auth.currentUser.getIdToken(false)
            }, '*');
        }
    }

}
