import {makeAutoObservable} from 'mobx';
import {getTime} from "html-evaluate-utils/DateFormat";
import axios from "axios";
import {API_URL, SAVE_SUBSCRIPTION_DETAILS} from "clicker-common/src/Config";
import {lc_linkedInAcc} from "clicker-common/src/staticData/lc_linkedInAcc";
import {DEV_MODE} from "./Config";

export class LinkedInAccState {

    authState = null;
    linkedInAcc = null;

    constructor() {
        makeAutoObservable(this);
    }

    setup(authState, caller) {
        console.log(getTime() + " LinkedInAccState.setup() <-" + caller);
        this.authState = authState;
        this.setLinkedInAcc(lc_linkedInAcc, " LinkedInAccState.setup() <-" + caller);
    }

    setLinkedInAcc(linkedInAcc, caller) {
        console.log(getTime() + " LinkedInAccState.setLinkedInAcc() <-" + caller);
        this.linkedInAcc = linkedInAcc;
    }

    save(updatedLinkedInAcc, userId, caller) {
        console.log(getTime() + " LinkedInAccState.save() <-" + caller);
        const endPointUrl = DEV_MODE ? API_URL : SAVE_SUBSCRIPTION_DETAILS;
        this.setLinkedInAcc(updatedLinkedInAcc, " LinkedInAccState.save() <-" + caller);
        if(userId && userId !== 0) {
            axios
                .post(
                    endPointUrl + "private/api/linkedInAcc/write/save",
                    updatedLinkedInAcc,
                    {headers: this.authState.authHeader(" LinkedInAccState.save() <-" + caller)})
                .then(response => {
                    if (response.status === 200) {
                        this.loadFromServiceByUserId(userId, caller);
                    }
                })
                .catch(reason => {
                    console.error(reason);
                });
        }
    }

    loadFromServiceByUserId(userId, caller) {
        console.log(getTime() + " LinkedInAccState.loadFromServiceByUserId() <-" + caller);
        const endPointUrl = DEV_MODE ? API_URL : SAVE_SUBSCRIPTION_DETAILS;
        if(userId && userId !== 0) {
            axios
                .get(endPointUrl + "private/api/linkedInAcc/find/by/userId?userId=" + userId,
                    {headers: this.authState.authHeader(" LinkedInAccState.loadFromServiceByUserId() <-" + caller)})
                .then((response) => {
                    if (response.status === 200) {
                        this.setLinkedInAcc(response.data, " LinkedInAccState.loadFromServiceByUserId(), response status 200 <-" + caller);
                    }
                })
                .catch(reason => {
                    console.error(reason);
                });
        }
    }

}