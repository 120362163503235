import {makeAutoObservable} from "mobx";
import {getTime} from "html-evaluate-utils/DateFormat";

export class ActorState {

    actorPostData = [];
    sendData = false;
    cfgState = null;

    constructor() {
        makeAutoObservable(this);
    }

    setup(clickActionState, cfgState, caller) {
        console.log(getTime() + " ActorState.setup() <- " + caller);
        this.cfgState = cfgState;
        this.actorPostData = [];
        this.sendData = false;
    }

}