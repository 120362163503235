import React from "react";
import {Helmet} from "react-helmet-async";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import {CardContent} from "@mui/material";

const Policy = () => {
    return (
        <>
            <Helmet>
                <title> Policy | Click Click Click </title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4">It's about policy</Typography>
                </Stack>
                <Card>
                    <CardContent>
                        Data about user or activity is not collect.
                    </CardContent>
                </Card>
            </Container>
        </>
    );
};

export default Policy;
