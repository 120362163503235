import {makeAutoObservable} from 'mobx';
import axios from "axios";
import {
    API_URL,
    SUBSCRIPTION_DETAILS_BY_USER_ID,
    SAVE_SUBSCRIPTION_DETAILS,
    CANCEL_SUBSCRIPTION_DETAILS,
    SUSPEND_SUBSCRIPTION_DETAILS,
    ACTIVATE_SUBSCRIPTION_DETAILS
} from "clicker-common/src/Config";
import {lc_subscription_details} from "clicker-common/src/staticData/lc_subscription_details";
import {getTime} from "html-evaluate-utils/DateFormat";
import {DEV_MODE} from "./Config";

export class SubscriptionDetailsState {
    list = null;
    authState = null;
    subscriptionDetails = null;
    dataLoading = false;
   
    constructor() {
        makeAutoObservable(this);
    }

    setup(authState, caller) {
        console.log(getTime() + " SubscriptionDetailsState.setup() <-" + caller);
        this.authState = authState;
        this.setSubscriptionDetails(lc_subscription_details," SubscriptionDetailsState.setup() <-" + caller);
    }

    setSubscriptionDetails(details, caller){
        console.log(getTime() + " SubscriptionDetailsState.setSubscriptionDetails() <-" + caller);
        this.subscriptionDetails = details;
    }

    getSubscriptionDetails(caller){
        console.log(getTime() + " SubscriptionDetailsState.getSubscriptionDetails() <-" + caller);
        return this.subscriptionDetails;
    }

    save(subscriptionDetails, uid, caller){
        console.log(getTime() + " SubscriptionDetailsState.save() <-" + caller);
        this.setSubscriptionDetails(subscriptionDetails," SubscriptionDetailsState.save() <-" + caller);
        const endPointUrl = DEV_MODE ? API_URL : SAVE_SUBSCRIPTION_DETAILS;
        if(uid && uid !== 0) {
            subscriptionDetails.uid = uid;
            axios
                .post(
                    endPointUrl + "saveSubscriptionDetails?uid=" + uid,
                    subscriptionDetails,
                    {headers: this.authState.authHeader(" SubscriptionDetailsState.save() <-" + caller)})
                .then((response) => {
                    if (response.status === 200) {
                        this.setSubscriptionDetails(
                            response.data,
                            " SubscriptionDetailsState.save(), response status 200 <-" + caller);
                        return this.subscriptionDetails;
                    } else {
                        const reason = "SubscriptionDetailsState.save response != 200";
                        console.error(reason);
                    }
                })
                .catch(reason => {
                    console.error("SubscriptionDetailsState.save reason " + reason);
                });
        } else {
            const reason = "SubscriptionDetailsState.save uid undefined";
            console.error(reason);
        }
    };
    
    loadFromServiceByUserId(uid, caller){
        console.log(getTime() + " SubscriptionDetailsState.loadFromServiceByUserId() <-" + caller);
        const endPointUrl = DEV_MODE ? API_URL : SUBSCRIPTION_DETAILS_BY_USER_ID;
        if(uid && uid !== 0) {
            axios
                .get(endPointUrl + "subscriptionDetailsByUserId?uid=" + uid,
                    {headers: this.authState.authHeader(" SubscriptionDetailsState.loadFromServiceByUserId() <-" + caller)})
                .then((response) => {
                    if (response.status === 200) {
                        this.setSubscriptionDetails(
                            response.data,
                            " SubscriptionDetailsState.loadFromServiceByUserId(), response status 200 <-" + caller);
                    } else {
                        const reason = "SubscriptionDetailsState.loadFromServiceByUserId, response status not 200 <-" + caller;
                        console.error(reason);
                    }
                })
                .catch(reason => {
                    console.error("SubscriptionDetailsState.loadFromServiceByUserId reason " + reason);
                });
        } else {
            const reason = "SubscriptionDetailsState.loadFromServiceByUserId uid undefined";
            console.error(reason);
        }
    };

    cancelSubscriptions(uid, caller) {
        console.log(getTime() + " SubscriptionDetailsState.cancelSubscriptions <-" + caller);
        const endPointUrl = DEV_MODE ? API_URL : CANCEL_SUBSCRIPTION_DETAILS;
        if (uid) {
            axios
                .post(endPointUrl + "cancelSubscriptions?uid=" + uid,
                     this.subscriptionDetails,
                    {headers: this.authState.authHeader(" SubscriptionDetailsState.cancelSubscriptions <-" + caller)})
                .then((response) => {
                    if (response.status === 200) {
                        console.log(getTime() + " SubscriptionDetailsState.cancelSubscriptions response 2000 <-" + caller);
                        this.setSubscriptionDetails(
                            response.data,
                            " SubscriptionDetailsState.cancelSubscriptions(), response status 200 <-" + caller);
                    } else {
                        const reason = "SubscriptionDetailsState.cancelSubscriptions, response status not 200 <-" + caller;
                        console.error(reason);
                    }
                })
                .catch(e => {
                    console.error("SubscriptionDetailsState.cancelSubscriptions e " + e);
                });
        } else {
            console.error("SubscriptionDetailsState.cancelSubscriptions uid undefined");
        }
    };

    suspendSubscriptions(uid, caller) {
        console.log(getTime() + " SubscriptionDetailsState.suspendSubscriptions <-" + caller);
        const endPointUrl = DEV_MODE ? API_URL : SUSPEND_SUBSCRIPTION_DETAILS;
        if (uid) {
            axios
                .post(endPointUrl + "suspendSubscriptions?uid=" + uid,
                    this.subscriptionDetails,
                    {headers: this.authState.authHeader(" SubscriptionDetailsState.suspendSubscriptions <-" + caller)})
                .then((response) => {
                    if (response.status === 200) {
                        console.log(getTime() + " SubscriptionDetailsState.suspendSubscriptions response 2000 <-" + caller);
                        this.setSubscriptionDetails(
                            response.data,
                            " SubscriptionDetailsState.suspendSubscriptions, response status 200 <-" + caller);
                    } else {
                        const reason = "SubscriptionDetailsState.suspendSubscriptions, response status not 200 <-" + caller;
                        console.error(reason);
                    }
                })
                .catch(e => {
                    console.error("SubscriptionDetailsState.suspendSubscriptions e " + e);
                });
        } else {
            console.error("SubscriptionDetailsState.suspendSubscriptions uid undefined");
        }
    };

    activateSubscriptions(uid, caller) {
        console.log(getTime() + " SubscriptionDetailsState.activateSubscriptions <-" + caller);
        const endPointUrl = DEV_MODE ? API_URL : ACTIVATE_SUBSCRIPTION_DETAILS;
        if (uid) {
            axios
                .post(endPointUrl + "activateSubscriptions?uid=" + uid,
                    this.subscriptionDetails,
                    {headers: this.authState.authHeader(" SubscriptionDetailsState.activateSubscriptions <-" + caller)})
                .then((response) => {
                    if (response.status === 200) {
                        console.log(getTime() + " SubscriptionDetailsState.activateSubscriptions response 2000 <-" + caller);
                        this.setSubscriptionDetails(
                            response.data,
                            " SubscriptionDetailsState.activateSubscriptions, response status 200 <-" + caller);
                    } else {
                        const reason = "SubscriptionDetailsState.activateSubscriptions, response status not 200 <-" + caller;
                        console.error(reason);
                    }
                })
                .catch(e => {
                    console.error("SubscriptionDetailsState.activateSubscriptions e " + e);
                });
        } else {
            console.error("SubscriptionDetailsState.activateSubscriptions uid undefined");
        }
    };
    
}