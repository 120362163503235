import 'src/global.css';
import {router} from 'src/routes/Router';
import {HelmetProvider} from 'react-helmet-async';
import ThemeProvider from 'src/theme';
import {RouterProvider} from "react-router-dom";

function App() {

    return (
        <HelmetProvider>
            <ThemeProvider>
                <RouterProvider router={router}/>
            </ThemeProvider>
        </HelmetProvider>
    );
}

export default App;
