import {makeAutoObservable} from 'mobx';
import {lc_cfg} from "clicker-common/src/staticData/lc_cfg";
import {getTime} from "html-evaluate-utils/DateFormat";
import axios from "axios";
import {API_URL, CFG_BY_USER_ID, SAVE_CFG} from "clicker-common/src/Config";
import {DEV_MODE} from "./Config";

export class CfgState {

    authState = null;
    rootCfg= null;
    dataLoaded = false;

    constructor() {
        makeAutoObservable(this);
    }

    setup(authState, caller) {
        console.log(getTime() + " CfgState.setup() <-" + caller);
        this.authState = authState;
        this.setRootCfg(lc_cfg, " CfgState.setup() <-" + caller);
    }

    setRootCfg(rootCfg, caller){
        console.log(getTime() + " CfgState.setRootCfg() <-" + caller);
        this.rootCfg = rootCfg;
    }

    save(uid, caller) {
        console.log(getTime() + " CfgState.save() <-" + caller);
        const endPointUrl = DEV_MODE ? API_URL : SAVE_CFG;
        if(uid && uid !== 0){
            axios.post(
                endPointUrl + "saveCfg?uid=" + uid,
                {
                    "cfg": this.rootCfg.cfg,
                    "uid": uid
                },
                {headers: this.authState.authHeader(" CfgState.save() <-" + caller)})
                .then(response => {
                    if (response.status === 200) {
                        this.setRootCfg(
                            response.data,
                            " CfgState.save(), response status 200");
                    } else {
                        console.error("status:" + response.status + ", data:" + response.data);
                    }
                }).catch(reason => {
                    console.error(reason);
                });
        }
    }

    loadFromServiceByUserId(uid, caller) {
        console.log(getTime() + " CfgState.loadFromServiceByUserId() <-" + caller);
        const endPointUrl = DEV_MODE ? API_URL : CFG_BY_USER_ID;
        if(uid && uid !== 0){
            axios
                .get(endPointUrl + "cfgByUserId?uid=" + uid,
                {headers: this.authState.authHeader(" CfgState.loadFromServiceByUserId() <-" + caller)})
                .then((response) => {
                    if (response.status === 200 && response.data && response.data.cfg) {
                        console.log(getTime() + " CfgState.loadFromServiceByUserId(), response status = 200 <-" + caller);
                        this.setRootCfg({
                            "uid" : response.data.uid,
                            "cfg" : response.data.cfg,
                        }, " CfgState.loadFromServiceByUserId(), response status = 200 <-" + caller)
                        this.dataLoaded = true;
                    } else {
                        console.error("status:" + response.status + ", data:" + response.data);
                    }
                })
                .catch(reason => {
                    console.error(reason);
                });
        }
    }

}
